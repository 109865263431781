import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import CoolDog from "../images/tanis.jpeg"
// import PostLink from "../components/post-link"
import { Helmet } from "react-helmet"

import imagePath from "../components/indeximagepath"


const IndexPage = ({
                     data: {
                       allMarkdownRemark: { edges },
                     },
                   }) => {
    const randomPostIndex = Math.floor(Math.random() * Math.floor(edges.length));
    const post = edges[randomPostIndex].node;


  return <Layout>
      <SEO title="hipster.dog" imageSrc={imagePath} />
      <h1>Dogs and Beards</h1>
      <div style={{ maxWidth: `700px`, marginBottom: `1.45rem` }}>
          <img src={CoolDog} alt='cool doggos'/>
      </div>
      <Helmet>
          <body className="bg-green-light"/>
      </Helmet>
      <div className="container mx-auto ">
          <div className="blog-post">
              <div className="mainTxt mainTxtPad">{post.frontmatter.title}</div>
          </div>
      </div>
      <div className="bottomTxt fixed pin-r pin-b">{post.frontmatter.date}</div>
  </Layout>
}

export default IndexPage

export const pageQuery = graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    id
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        path
                        title
                    }
                }
            }
        }
    }
`