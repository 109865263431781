import { getSrc } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby";


const IndexImagePath = () => {
    const {image} = useStaticQuery(
      graphql`
          query {
              file(relativePath: { eq: "../images/tanis.jpeg" }) {
                  childImageSharp {
                      gatsbyImageData(layout: FIXED)
                  }
              }
          }
      `
    )

  return getSrc(image.file)
}

export default IndexImagePath
